<template>
  <div class="uk-section">
    <div class="uk-container uk-margin-top uk-margin-medium-bottom" v-if="! loading">
      <div
        class="uk-grid uk-child-width-1-2@m in-testimonial-2"
        data-uk-grid=""
      >
        <div class="uk-width-1-1@m uk-text-center uk-first-column">
          <h1 class="in-highlight">{{ content.intro.title }}</h1>
          <p v-html="content.intro.content"></p>
        </div>
      </div>
      <div class="uk-grid" v-for="(broker, i) in content.brokers" :key="`broker-${i}`">
        <div class="uk-width-1-1">
          <div :class="`uk-card uk-card-${i%2 == 0 ? 'secondary' : 'primary'}`">
            <div class="uk-card-body">
              <div
                class="
                  uk-grid-divider
                  uk-child-width-1-1
                  uk-child-width-1-3@m
                  uk-grid
                "
              >
                <div class="uk-first-column">
                  <img :src="broker.logo" :alt="broker.title" style="height: 100px" />
                  <h2 class="mb-0">{{ broker.title }}</h2>
                  <p>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    4.8
                  </p>
                  <a
                    :href="broker.link"
                    target="_blank"
                    class="
                      uk-button
                      uk-button-primary
                      bg-red
                      uk-margin-top
                    "
                    >Start Trading NOW!</a
                  >
                </div>
                <div>
                  <ul>
                    <li class="text-bold" v-for="(feature, j) in broker.features" :key="`feature-${i}-${j}`">{{ feature }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      content: {},
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get('/api/v1/pages/top-brokers').then(response => {
        this.content = response.data.content
        this.loading = false
      })
    }
  }
};
</script>
